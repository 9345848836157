@import 'variables';

.project-wrapper {
    display: block;
    padding: 110px 0;

    @media screen and (max-width: 960px) {
        padding: 55px 0 0;

        &:last-child {
            padding: 55px 0;
        }
    }

    &-video {
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 190px;
            height: 190px;
            margin: auto;
            background: transparent;
            border: none;
            z-index: 3;
            opacity: 1;
            transition: all 0.2s ease; 

            &[data-playing="true"] {
                opacity: 0;
            }

            @media screen and (max-width: 960px) {
                transform: scale(0.5)
            }
        }
    }

    img,
    video {
        display: block;
        width: calc(100% - 36px);
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        border-radius: 20px;
        overflow: hidden;

        &:hover {
            cursor: pointer;
        }
    }

    video {
        opacity: 1;
        transition: all 0.2s ease; 
        background: black;

        &[data-playing="false"] {
            opacity: 0.3;
        }
    }

    .project-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 36px 18px;

        h3,
        h4,
        h5 {
            color: $dark;
            font-family: 'Inter', sans-serif;
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: 26.669px; /* 66.672% */
            text-transform: uppercase;

            @media screen and (max-width: 960px) {
                font-size: 18px;
                line-height: 18px;
            }
        }

        &--divider {
            display: block;
            width: calc(100% - 36px);
            height: 2px;
            margin: 0 auto;
            background: $dark;
        }

        h6 {
            display: block;
            width: 80%;
            color: $dark;
            font-family: 'DM Sans', sans-serif;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 112.1%; /* 44.84px */

            @media screen and (max-width: 960px) {
                width: 100%;
                font-size: 18px;
                line-height: 18px;
            }
        }
    }

    .project-url {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-right: 18px;
        padding-top: 110px;

        @media screen and (max-width: 960px) {
            padding-top: 0;
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            color: #FFF;
            font-family: 'Inter', sans-serif;
            font-size: 60px;
            font-style: normal;
            font-weight: 800;
            line-height: 60px; /* 100% */
            letter-spacing: -2px;
            text-decoration-line: underline;
            text-transform: uppercase;

            @media screen and (max-width: 960px) {
                font-size: 24px;
            }
        }
    }
}