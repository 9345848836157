@import 'variables';

.gallery {
    display: block;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    padding-bottom: 120px;
    background: radial-gradient(50% 50% at 50% 50%, #2C211B 0%, #0D0D0D 100%);

    h2 {
        padding-top: 140px;
        padding-left: 18px;
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: $bigtext-desktop;
        font-style: normal;
        font-weight: 800;
        line-height: 87.1%; /* 174.2px */
        text-transform: uppercase;

        @media screen and (max-width: 960px) {
            font-size: $bigtext-mobile;
        }

        .projects-words-wrapper {
          display: block;
          height: 100%;
          overflow: hidden;

          span {
            display: block;
            transform: translate3d(0, 180px, 0);
          }

          &[data-show="true"] {
              &:first-child {
                  animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;

                  span {
                      animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
                  }
              }

              &:last-child {
                  animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;

                  span {
                      animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;
                  }
              }
          }
        }

        @keyframes slideUp {
          from {transform: translate3d(0, 180px, 0);}
          to {transform: translate3d(0, 0, 0);}
        }

        @keyframes moveUp {
            from {transform: translate3d(0, 40px, 0);}
            to {transform: translate3d(0, 0, 0);}
        }
    }

    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap; /* Allow tags to wrap onto multiple lines */
        width: calc(100% - 36px);
        margin: 40px auto 0;

        @media screen and (max-width: 960px) {
            flex-direction: column;
        }
    }

    &-item {
        &-wrapper {
            &:hover {
                cursor: no-drop;
            }
        }

        &-wrapper,
        a {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-right: -40px;
            // border: 1px solid red;
            line-height: 14px;
            opacity: 0.2;
            transition: 0.3s opacity ease;

            &:hover {
                opacity: 1;
                z-index: 10;

                .gallery-item-tags {
                    transform: scale(1) translate3d(-30px,0,0);
                    filter: saturate(1);
                }
            }

            svg {
                display: none;
            }

            @media screen and (max-width: 960px) {
                opacity: 0.8;

                svg {
                    display: block;
                    transform: rotate(-45deg) scale(0.5) translate3d(-10px,-10px,0);
                    margin-right: -10px;
                }
            }
        }

        h6 {
            color: #FFF;
            font-family: 'Inter', sans-serif;
            // font-size: 60px;
            font-size: 4vw;
            font-style: normal;
            font-weight: 800;
            line-height: 60px; /* 100% */
            letter-spacing: -2px;
            text-transform: uppercase;
            white-space: nowrap; /* Prevent text from wrapping */

            @media screen and (max-width: 960px) {
                font-size: 32px;
                line-height: 32px;
            }
        }

        &-tags {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            transform: scale(0.6) translate3d(10px,0,0);
            filter: saturate(0);
            transform-origin: 0 center;
            transition: 0.3s all ease;
            pointer-events: none;

            span {
                font-family: 'Inter', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 800;

                &:nth-child(odd) {
                    padding: 6px 10px;
                    background: $green;
                    color: $yellow;
                }

                &:nth-child(even) {
                    padding: 6px 10px;
                    background: $yellow;
                    color: $green;
                }
            }

            @media screen and (max-width: 960px) {
                filter: saturate(1);
                transform: scale(0.8) translate3d(20px,-4px,0);

                span {
                    font-size: 14px;
                }
            }
        }
    }
}

// .scope {
//     position: relative;
//     width: 200px;
//     height: 200px;
//     margin: 200px auto;
//     transform-style: preserve-3d;
//     animation: slide 100s linear infinite;
//   }
  
//   .scope span {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     transform-origin: center;
//     transform-style: preserve-3d;
//     transform: rotateY(calc(var(--i) * 45deg)) translateZ(600px);
//   }

//   .scope span img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;
//     object-fit: contain;
//     transition: 2s;
//     user-select: none;
// }

//   @keyframes slide {
//     0% {
//       transform: perspective(1000px) rotateY(0deg);
//     }
//     100% {
//       transform: perspective(1000px) rotateY(360deg);
//     }
//   }