@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500,700&family=Inter:wght@700;800&display=swap');

@import 'intro';
@import 'nav';
@import 'clients';
@import 'list';
@import 'footer';
@import 'gallery';
@import 'projects';

html {
    scroll-behavior: initial;
}

html,
body {
    max-width: 100%;
    min-height: 100%;
    height: auto;
}

::selection {
    color: $yellow;
    background: $green;
}

a:hover {
    .arrow-wrapper {
        &--large {
            svg {
                transform: rotate(-45deg) translate3d(100px, 0, 0);
            }
    
            svg + svg {
                transform: rotate(-45deg) translate3d(46px, -55px, 0);
            }
        }

        &--medium {
            svg {
                transform: rotate(-45deg) translate3d(40px, 0, 0);
            }
    
            svg + svg {
                transform: rotate(-45deg) translate3d(19px, -21px, 0);
            }
        }

        &--small {
            svg {
                transform: scale(0.7) rotate(-45deg) translate3d(40px, 0, 0);
            }
    
            svg + svg {
                transform: scale(0.7) rotate(-45deg) translate3d(28px, -25px, 0)
            }
        }
    }
}

.arrow-wrapper {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    overflow: hidden;

    &--large {
        width: 55px;
        height: 55px;

        svg {
            transform: rotate(-45deg) translate3d(2px, -10px, 0);
            transition: 0.3s all ease-out;
        }
    
        svg + svg {
            transform: rotate(-45deg) translate3d(-30px, -35px, 0);
            transition-delay: 0.10s;
        }

        @media screen and (max-width: 960px) {
            transform: scale(0.3) translate3d(60px, 35px, 0);;
        }
    }

    &--medium {
        svg {
            transform: rotate(-45deg);
            transition: 0.3s all ease-out;
        }
    
        svg + svg {
            transform: rotate(-45deg) translate3d(-5px, -25px, 0);
            transition-delay: 0.10s;
        }
    }

    &--small {
        svg {
            transform: scale(0.7) rotate(-45deg);
            transition: 0.3s all ease-out;
        }
    
        svg + svg {
            transform: scale(0.7) rotate(-45deg) translate3d(-5px, -25px, 0);
            transition-delay: 0.10s;
        }
    }
}