.clients {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 18px);
    min-height: 100vh;
    margin-left: 18px;
    overflow: hidden;

    @media screen and (max-width: 960px) {
        min-height: 50vh;
    }

    h2 {
        position: absolute;
        top: calc(50% - 174px);
        left: 0%;
        transform: translate(0%, -50%);
        display: block;
        max-width: 90%;
        margin: auto;
        color: $yellow;
        font-family: 'Inter', sans-serif;
        font-size: $bigtext-desktop;
        font-style: normal;
        font-weight: 800;
        line-height: 87.1%; /* 174.2px */
        text-transform: uppercase;
        z-index: 1;
        pointer-events: none;

        + h2 {
            top: 50%;
            z-index: 9999;
        }

        + h2 + h2 {
            top: calc(50% + 174px);
            z-index: 1;
        }

        @media screen and (max-width: 960px) {
            top: calc(50% - 50px);
            font-size: $bigtext-mobile;
            line-height: $bigtext-mobile;

            + h2 + h2 {
                top: calc(50% + 50px);
            }
        }

        .clients-word-wrapper {
            display: block;
            height: 100%;
            overflow: hidden;

            span {
                display: block;
                transform: translate3d(0, 180px, 0);
            }

            &[data-show="true"] {
                &:first-child {
                    animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
    
                    span {
                        animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
                    }
                }
    
                &:nth-child(2) {
                    animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;
    
                    span {
                        animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;
                    }
                }
    
                &:last-child {
                    animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.5s 1 forwards;
    
                    span {
                        animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.5s 1 forwards;
                    }
                }
            }
        }

        @keyframes slideUp {
            from {transform: translate3d(0, 180px, 0);}
            to {transform: translate3d(0, 0, 0);}
        }

        @keyframes moveUp {
            from {transform: translate3d(0, 40px, 0);}
            to {transform: translate3d(0, 0, 0);}
        }
    }

    &-move {
        position: absolute;
        top: auto;
        bottom: auto;
        right: 5%;
        display: flex;
        flex-direction: column;
        margin: auto;

        &--desktop {
            display: flex;

            @media screen and (max-width: 960px) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media screen and (max-width: 960px) {
                display: flex;
            }
        }

        &[data-show="true"] {
            span {
                animation: fadeInOut 1s ease 1;
            }
        }

        span {
            color: #0E2F2B; //#E6E3A3
            font-family: "DM Sans", sans-serif;
            font-size: 30px;
            font-style: normal;
            opacity: 0;
            filter: blur(1px);            
            
            $total-items: 10;
            @for $i from 1 through $total-items {
    
                &:nth-child(#{$i}) {
                    animation-delay: calc(0.1s * $i);
                }
            }

            @keyframes fadeInOut {
                0% { opacity: 0;}
                50% { opacity: 0.7;}
                100% { opacity: 0;}
            }
        }

    }
}

.trail-img-wrapper {
    position: relative;
    z-index: 2;

    img {
        width: 206px;
        height: 206px;
        opacity: 1;

        @media screen and (max-width: 960px) {
            width: 160px;
            height: 160px;
        }
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
        transform: translate3d(0,150px,0);
    }
}
