.press {
  &-wrapper {
    width: 50vw;
    width: calc(100vw - 36px);
    margin-left: 18px;
    margin-right: 18px;
    padding: 110px 0;

    @media screen and (max-width: 960px) {
      padding: 55px 0;
    }

    h2 {
      display: block;
      height: 100%;
      overflow: hidden;
      color: $yellow;
      font-family: 'Inter', sans-serif;
      font-size: $bigtext-desktop;
      font-style: normal;
      font-weight: 800;
      line-height: 87.1%; /* 174.2px */
      text-transform: uppercase;

      @media screen and (max-width: 960px) {
        font-size: $bigtext-mobile;
      }

      span {
        display: block;
        transform: translate3d(0, 180px, 0);
      }

      &[data-show="true"] {
        animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;

        span {
            animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
        }
      }

      @keyframes slideUp {
        from {transform: translate3d(0, 180px, 0);}
        to {transform: translate3d(0, 0, 0);}
      }

      @keyframes moveUp {
          from {transform: translate3d(0, 40px, 0);}
          to {transform: translate3d(0, 0, 0);}
      }
      
      @keyframes slideUpList {
        from {transform: translate3d(0, 60px, 0);}
        to {transform: translate3d(0, 0, 0);}
      }

      @keyframes moveUpItem {
        from {transform: translate3d(0, 110px, 0);}
        to {transform: translate3d(0, 0, 0);}
      }
      
    }

    ul {
      position: relative;
      width: 100%;
      margin: 130px 0 0 0;
      padding: 0;

      @media screen and (max-width: 960px) {
        margin: 55px 0 0 0;
      }

      &[data-show="true"] {
        li {
          animation: slideUpList 0.5s ease-out;
          animation-fill-mode: backwards;
        }
      }
  
      li {
        display: block;
        overflow: hidden;
        height: 100%;
        list-style: none;

        $total-images: 7;
          $animation-duration: 0.5s;
          $animation-delay: 0.1s;
          @for $i from 1 through $total-images {

            &:nth-child(#{$i}) {
              animation-delay: calc(0.1s * $i);
            }

            &:hover {
              cursor: no-drop;
              img:nth-child(#{$i}) {
                // transform: translate3d(-50%, -50%,0) rotate(#{25 - ($i * 6)}deg);
                opacity: 1;
              }
            }

            &:nth-child(3) img {
              transform: translate3d(-50%, -30%, 0) rotate(10deg);
              opacity: 0;
            }

            &:nth-child(5) img {
              transform: translate3d(-50%, -26%, 0) rotate(0deg) !important;
              opacity: 0;
            }

            &:nth-child(7) img {
              transform: translate3d(-50%, -20%, 0) rotate(-10deg) !important;
              opacity: 0;
            }

            &:nth-child(#{$i}) {
              img {
                transform: translate3d(-50%, -50%,0) rotate(#{25 - ($i * 5)}deg);
                opacity: 0;
                // animation: fadeOut $animation-duration ease forwards;
                // animation-delay: ($animation-delay * ($total-images - $i));
              }
            }
          }
          
        .press-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 36px 0;
          border-top: 1px solid $dark;
          width: 100%;
          transform: translate3d(0, 110px, 0);
          animation: moveUpItem 0.5s ease-out 1s 1 forwards;

          @media screen and (max-width: 960px) {
            padding: 18px 0;
          }
        }

        span {
          position: relative;
          z-index: 2;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          // transform: translate(-50%, -50%);
          width: 600px;
          height: auto;
          z-index: 1;
          transition: all 0.5s ease;
          // mix-blend-mode: difference;
        }
      }
    }
  }

  &-title {
    display: block;
    width: 45%;
    color: $dark;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    text-transform: uppercase;

    @media screen and (max-width: 960px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &-article {
    display: block;
    width: 35%;
    text-align: left;
    color: $dark;
    font-family: 'DM Sans', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    @media screen and (max-width: 960px) {
      margin-left: 10px;
      font-size: 20px;
      line-height: 20px;
    }

    &--wide {
      width: 90%;
    }
  }

  &-link {
    padding-right: 10%;

    @media screen and (max-width: 960px) {
      padding-right: 0;
    }
  }
}