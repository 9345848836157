@import 'variables';

.footer {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 0 18px;
    height: 100vh;
    padding-top: 20vh;
    background: $yellow;
  }


  &-middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @media screen and (max-width: 960px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    h5 {
      max-width: 100%;
      color: $dark;
      font-family: 'Inter', sans-serif;
      font-size: 13vw;
      font-style: normal;
      font-weight: 800;
      line-height: 87.1%; /* 174.2px */
      text-transform: uppercase;

      @media screen and (max-width: 960px) {
        font-size: $bigtext-mobile;
        line-height: 100%;
      }

      .footer-words-wrapper {
        display: block;
        overflow: hidden;
        height: 100%;

        @media screen and (max-width: 960px) {
          height: auto;
        }
        
        span {
          display: block;
          transform: translate3d(0, 180px, 0);
        }

        &[data-show="true"] {
          &:first-child {
              animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;

              span {
                  animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
              }
          }

          &:last-child {
              animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;

              span {
                  animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;
              }
          }
      }
      }

      @keyframes slideUp {
        from {transform: translate3d(0, 180px, 0);}
        to {transform: translate3d(0, 0, 0);}
      }

      @keyframes moveUp {
          from {transform: translate3d(0, 40px, 0);}
          to {transform: translate3d(0, 0, 0);}
      }

    }

    ul {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 35%;
      margin-bottom: -150px;
      z-index: 5;

      @media screen and (max-width: 960px) {
        justify-content: flex-start;
        padding: 0;
        margin-left: -30px;
        margin-bottom: 0;
      }

      li {
        display: block;
        height: 100%;
        overflow: hidden;
        list-style: none;
        margin-bottom: 40px;

        @media screen and (max-width: 960px) {
          margin-bottom: 0;
        }

        .footer-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          transform: translate3d(0, 180px, 0);
        }

        &[data-show="true"] {
          animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;

          .footer-item {
              animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;
          }
          
          $total-items: 3;
          @for $i from 1 through $total-items {
            &:nth-child(#{$i}) {
              

              .footer-item {
                animation-delay: calc(0.15s * $i);
              }
            }
          }
        }

        a {
          display: flex;
          flex-direction: row;
          margin-left: 16px;
          color: $dark;
          font-family: 'Inter', sans-serif;
          font-size: 4vw;
          font-style: normal;
          font-weight: 800;
          line-height: 60px; /* 100% */
          letter-spacing: -2px;
          text-transform: uppercase;

          @media screen and (max-width: 960px) {
            margin-left: 0;
            font-size: 24px;
            letter-spacing: 0;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h6 {
      color: $dark;
      text-align: right;
      font-family: 'DM Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.669px; /* 111.12% */

      &:first-of-type {
        text-align: left;
      }

      small {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        line-height: 12px;
      }

      @media screen and (max-width: 960px) {
        font-size: 16px;
      }
    }
  }
}