// @font-face {
//     font-family: 'Satoshi-Medium';
//     src: url('../fonts/Satoshi-Medium.woff2') format('woff2'),
//         url('../fonts/Satoshi-Medium.woff') format('woff'),
//         url('../fonts/Satoshi-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-display: swap;
//     font-style: normal;
// }

$breakpoint: 992px;
$breakpoint1439: 1439px;
$breakpoint1800: 1800px;

//colors
$white: #ffffff;
$black: #000000;
$green: #0E2F2B;
$textlight: #EEE6D8;
$yellow: #E6E3A3;
// $dark: #1D1D1B;
$dark: #0E2F2B;

// font sizes
$bigtext-desktop: 200px;
$bigtext-mobile: 48px;
$midtext-mobile: 22px;

//
@custom-media --xsmall (width >= 400px);
@custom-media --small (width >= 540px);
@custom-media --medium-small (width >= 720px);
@custom-media --medium (width >= 960px);
@custom-media --medium-large (width >= 1140px);
@custom-media --large (width >= 1380px);

/* below */
@custom-media --below-xsmall (width < 400px);
@custom-media --below-small (width < 540px);
@custom-media --below-medium-small (width < 876px);
@custom-media --below-medium (width < 960px);
@custom-media --below-medium-large (width < 1140px);
@custom-media --below-large (width < 1380px);