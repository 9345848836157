@import 'variables';
// @media screen and (max-width: 960px) {
//     display: none;
// }

nav {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 32px 18px 0;
    width: 100vw;
    max-width: 100%;
    background-color: transparent;
    z-index: 99999;

    @media screen and (max-width: 960px) {
        padding: 18px 18px;
        background-color: $yellow;
    }

    a {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;
        color: $dark;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 26.669px; /* 102.572% */
        text-transform: uppercase;

        span {
            display: flex;
            flex-direction: row;
            transform: translate3d(0, 60px, 0);
            animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
        }
    }

    @media screen and (max-width: 960px) {
        a {
            font-size: 16px;

            &.hide-mobile {
                display: none;;
            }
        }
    }

    @keyframes slideUp {
        from {transform: translate3d(0, 60px, 0);}
        to {transform: translate3d(0, 0, 0);}
    }
}