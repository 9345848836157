@import 'variables';

.intro {
    &-wrapper {
        min-height: 100vh;
        padding: 0 18px;
        background-color: $yellow;

        &-textwrapper {
            padding-top: 110px;
            padding-left: 0;

            h1 {
                display: flex;
                flex-direction: column;
                margin: 0 0 44px;
                padding: 0;
                font-family: 'Inter', sans-serif;
                font-weight: 800;
                font-size: 190px;
                line-height: 88%;
                color: $dark;
                text-transform: uppercase;

                .word-wrapper {
                    display: block;
                    height: 88%;
                    overflow: hidden;

                    span {
                        display: block;
                        transform: translate3d(0, 180px, 0);
                    }

                    &:first-child {
                        animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;

                        span {
                            animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 forwards;
                        }
                    }

                    &:last-child {
                        animation: moveUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;

                        span {
                            animation: slideUp 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.25s 1 forwards;
                        }
                    }
                }
            }

            h2 {
                display: block;
                max-width: 75vw;
                font-family: 'DM Sans', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                color: $dark;
                animation: moveUpAndFadeIn 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.75s 1 forwards;
                transform: translate3d(0, 60px, 0);
                opacity: 0;
                filter: blur(2px);
            }

            @media screen and (max-width: 960px) {
                h1 {
                    font-size: 42px;
                }

                h2 {
                    max-width: calc(100vw - 18px);
                    font-size: $midtext-mobile;
                }
            }
        }

        @keyframes slideUp {
            from {transform: translate3d(0, 180px, 0);}
            to {transform: translate3d(0, 0, 0);}
        }

        @keyframes moveUp {
            from {transform: translate3d(0, 40px, 0);}
            to {transform: translate3d(0, 0, 0);}
        }

        @keyframes moveUpAndFadeIn {
            from {
                transform: translate3d(0, 60px, 0);
                opacity: 0;
                filter: blur(2px);
            }

            to {
                transform: translate3d(0, 0, 0);
                opacity: 1;
                filter: blur(0);
            }
        }

        @keyframes imgFadeIn {
            from {
                transform: translate3d(0, 40px, 0);
                opacity: 0;
                filter: blur(10px);
            }

            to {
                transform: translate3d(0, 0, 0);
                opacity: 1;
                filter: blur(0);
            }
        }

        img {
            position: relative;
            display: block;
            width: 667px;
            margin: 0 0 0 auto;
            padding-bottom: 100vh;
            border-radius: 20px;
            overflow: hidden;
            z-index: 2;
            transform: translate3d(0, 20px, 0);
            opacity: 0;
            filter: blur(10px);
            animation: imgFadeIn 0.95s cubic-bezier(0.16, 1, 0.3, 1) 0.5s 1 forwards;

            @media screen and (max-width: 960px) {
                width: 70vw;
                height: auto;
                min-height: 200px;
                padding-bottom: 820px;
            }
        }
    }

    
}

